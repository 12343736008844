/* eslint-disable import/no-cycle */
import { signOut } from '@/contexts/AuthContext';
import axios, { AxiosError } from 'axios';
import { parseCookies, setCookie } from 'nookies';
import { AuthTokenError } from './errors/AuthTokenError';

let isRefreshing = false;
let failedRequestsQueue: any[] = [];

export function setupAPIClient(ctx = undefined) {
  let cookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Authorization: `Bearer ${cookies['aguaamigao.token']}`,
    },
  });

  function onSuccess(response: any) {
    return response;
  }

  function onError(error: AxiosError<{ message: string }>) {
    if (error.response?.status === 401) {
      if (error.response.data?.message === 'Invalid token') {
        cookies = parseCookies(ctx);

        const { 'aguaamigao.refreshToken': refreshToken } = cookies;
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .put('sessions', {
              refresh_token: refreshToken,
            })
            .then((response) => {
              const { token } = response.data;

              setCookie(ctx, 'aguaamigao.token', token, {
                maxAge: 60 * 60 * 24 * 30, // 30 days
                path: '/',
              });

              setCookie(
                ctx,
                'aguaamigao.refreshToken',
                response.data.refresh_token,
                {
                  maxAge: 60 * 60 * 24 * 30, // 30 days
                  path: '/',
                }
              );

              // eslint-disable-next-line dot-notation
              api.defaults.headers['Authorization'] = `Bearer ${token}`;

              failedRequestsQueue.forEach((request) =>
                request.onSuccess(token)
              );
              failedRequestsQueue = [];
            })
            .catch((err) => {
              failedRequestsQueue.forEach((request) => request.onFailure(err));
              failedRequestsQueue = [];

              if (typeof window !== 'undefined') {
                signOut();
              }
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              if (originalConfig) {
                originalConfig.headers.Authorization = `Bearer ${token}`;

                resolve(api(originalConfig));
              }

              resolve(api);
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }
      if (error.response.data?.message === 'Invalid credentials') {
        return Promise.reject(error);
      }
      if (typeof window !== 'undefined') {
        signOut();
      } else {
        return Promise.reject(new AuthTokenError());
      }
    }

    return Promise.reject(error);
  }

  api.interceptors.response.use(onSuccess, onError);

  return api;
}
