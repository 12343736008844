import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import UpdateIcon from '@mui/icons-material/Update';
import { useAppVersionUpdateContext } from '@/contexts/AppVersionUpdateContext';

export const AppUpdateModal = () => {
  const { showUpdateModal, setShowUpdateModal, handleUpdateAppVersion } =
    useAppVersionUpdateContext();

  const handleUpdate = () => {
    handleUpdateAppVersion();
  };

  const handleClose = () => {
    setShowUpdateModal(false);
  };

  return (
    <Dialog
      open={showUpdateModal}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <Box textAlign="center" mt={2}>
        <WarningAmberIcon color="warning" style={{ fontSize: 50 }} />
      </Box>
      <DialogTitle>
        <Box display="flex" justifyContent="center">
          <Typography variant="h6" fontWeight={500}>
            Nova Versão Disponível
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="body1" align="center" gutterBottom>
            Uma nova versão do aplicativo está disponível. Atualize para obter
            os últimos recursos e melhorias.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%" mb={2}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            style={{ marginRight: 10 }}
          >
            Agora não
          </Button>
          <Button
            onClick={handleUpdate}
            color="primary"
            variant="contained"
            startIcon={<UpdateIcon />}
          >
            Atualizar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
