import Head from 'next/head';
import { AppProps } from 'next/app';
import { SnackbarProvider } from 'notistack';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from '@/contexts/AuthContext';
import { theme } from '@/styles/theme';
import createEmotionCache from '@/config/createEmotionCache';
import { queryClient } from '@/services/queryClient';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AppVersionUpdateProvider } from '@/contexts/AppVersionUpdateContext';
import { AppUpdateModal } from '@/components/common/AppVersionUpdateModal';

import '../styles/globals.css';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}
function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const AnyComponent = Component as any;

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="google" content="notranslate" key="notranslate" />
        <meta
          name="description"
          content="Distribuição de água mineral natural em Alagoas. Desde 1995 fazemos distribuição de água mineral com qualidade, profissionalismo e dedicação"
        />
        <link rel="icon" href="/favicon.ico" />
        <title>Água Amigão | Web</title>
      </Head>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <AuthProvider>
                <AppVersionUpdateProvider>
                  <AppUpdateModal />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AnyComponent {...pageProps} />
                  </LocalizationProvider>
                </AppVersionUpdateProvider>
              </AuthProvider>
            </SnackbarProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

export default MyApp;
