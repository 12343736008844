/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

'use client';

import { redirect, useRouter } from 'next/navigation';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getUserRoleAvailablePages } from '@/modules/core/auth/utils/getUserRoleAvailablePages';
import { AxiosResponse } from 'axios';
import { api } from '@/services/apiClient';
import { useSnackbar } from 'notistack';

type User = {
  name: string;
  email: string;
  is_admin: boolean;
  role: any;
  staff_id: string;
};

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut: () => void;
  clientSideSignOut: () => void;
  updateUser: (user: User) => void;
  user: User | undefined;
  isAuthenticated: boolean;
  isSignInSuccess: boolean;
  setIsSignInSuccess: (value: boolean) => void;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext({} as AuthContextData);

export function signOut() {
  destroyCookie(undefined, 'aguaamigao.token');
  destroyCookie(undefined, 'aguaamigao.refreshToken');

  redirect('/');
}

export function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();

  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [user, setUser] = useState<User>();
  const isAuthenticated = !!user;

  useEffect(() => {
    const { 'aguaamigao.token': token } = parseCookies();

    if (token) {
      api
        .get('profile')
        .then((response: AxiosResponse) => {
          const {
            name,
            email,
            is_admin: isAdmin,
            role,
            staff_id: staffId,
          } = response.data;

          setUser({
            name,
            email,
            is_admin: isAdmin,
            role,
            staff_id: staffId,
          });
        })
        .catch(() => {
          clientSideSignOut();
        });
    }
  }, []);

  async function signIn({ email, password }: SignInCredentials) {
    try {
      const response = await api.post('sessions', { email, password });
      setIsSignInSuccess(true);

      const {
        token,
        refresh_token: refreshToken,
        user: loggedUser,
      } = response.data;

      setCookie(undefined, 'aguaamigao.token', token, {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: '/',
      });
      setCookie(undefined, 'aguaamigao.refreshToken', refreshToken, {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: '/',
      });

      setUser({
        name: loggedUser.name,
        email,
        is_admin: loggedUser.is_admin,
        role: loggedUser.role,
        staff_id: loggedUser.staff_id,
      });

      // eslint-disable-next-line dot-notation
      api.defaults.headers['Authorization'] = `Bearer ${token}`;

      const userAvailablePages: string[] = getUserRoleAvailablePages(
        loggedUser?.role?.permissions
      );

      if (loggedUser.is_admin || userAvailablePages.includes('dashboard')) {
        router.push('/dashboard');
      } else {
        const firstAvailablePage = userAvailablePages[0];

        if (!firstAvailablePage) {
          enqueueSnackbar('Usuário sem permissão para acessar páginas', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'bottom',
            },
          });
          return;
        }

        router.push(`/${firstAvailablePage}`);
      }

      enqueueSnackbar(`Seja bem-vindo: ${loggedUser.name}`, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      });
    } catch (error: any) {
      setIsSignInSuccess(false);
      const errorMessage =
        error.response?.data?.message || 'Não foi possível realizar o login';
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      });
    }
  }

  const clientSideSignOut = () => {
    destroyCookie(undefined, 'aguaamigao.token');
    destroyCookie(undefined, 'aguaamigao.refreshToken');

    router.push('/');
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        clientSideSignOut,
        isAuthenticated,
        isSignInSuccess,
        setIsSignInSuccess,
        user,
        updateUser: setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within the AuthProvider');
  }

  return context;
};
