import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';

const APP_VERSION_LOCAL_STORAGE_KEY = 'aguaamigao.appVersion';

interface AppVersionUpdateContextType {
  showUpdateModal: boolean;
  // eslint-disable-next-line no-unused-vars
  setShowUpdateModal: (show: boolean) => void;
  handleUpdateAppVersion: () => void;
}

const AppVersionUpdateContext = createContext(
  {} as AppVersionUpdateContextType
);

export const useAppVersionUpdateContext = () => {
  const context = useContext(AppVersionUpdateContext);
  if (!context) {
    throw new Error(
      'useAppVersionUpdateContext must be used within an AppUpdateProvider'
    );
  }
  return context;
};

export const AppVersionUpdateProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    const localVersion = localStorage.getItem(APP_VERSION_LOCAL_STORAGE_KEY);
    const latestVersion = process.env.NEXT_PUBLIC_APP_VERSION;

    if (!localVersion) {
      localStorage.setItem(APP_VERSION_LOCAL_STORAGE_KEY, latestVersion || '');
      return;
    }

    if (localVersion !== latestVersion) {
      setShowUpdateModal(true);
    }
  }, [setShowUpdateModal]);

  const handleUpdateAppVersion = () => {
    const latestVersion = process.env.NEXT_PUBLIC_APP_VERSION;
    localStorage.setItem(APP_VERSION_LOCAL_STORAGE_KEY, latestVersion || '');
    window.location.reload();
  };

  return (
    <AppVersionUpdateContext.Provider
      value={{ showUpdateModal, setShowUpdateModal, handleUpdateAppVersion }}
    >
      {children}
    </AppVersionUpdateContext.Provider>
  );
};
